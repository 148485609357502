import React from "react";
import cx from "classnames";
import "./index.less";
import StoriesWithPopup from "../StoriesWithPopup/StoriesWithPopup";

const Timeline = ({
  title,
  content,
  hasDetail,
  itineraryWidth,
  borderType,
}) => {
  return (
    <div className={cx("timeline", { "timeline-detail": hasDetail })}>
      {title && (
        <>
          <span className="font-mulish uppercase text-12-18 tracking-8 font-bold text-gold">
            {title}
          </span>
          <div class="border-t border-gray-light"></div>
        </>
      )}
      <ul
        className={cx("mt-20 list-none m-0 p-0 box-border", {
          "max-w-575": itineraryWidth,
          "max-w-320": !itineraryWidth,
        })}>
        {content.map((object, idx) => {
          const { time, label, description, subdescription, stories } = object;

          const storyCards = stories && [
            {
              title: stories.title,
              mobile: stories.images[0].mobile,
              desktop: stories.images[0].desktop,
              images: [
                {
                  ...stories.story_images[0],
                  desktop: stories.story_images[0].desktop[0],
                  mobile: stories.story_images[0].mobile[0],
                },
              ],
            },
          ];

          return (
            <li
              key={label}
              className={cx("timeline-item", {
                "item-first": idx === 0 && borderType === "solid",
                "item-last":
                  idx === content.length - 1 && borderType === "solid",
                "item-first-itinerary": idx === 0 && borderType === "dashed",
                "item-last-itinerary":
                  idx === content.length - 1 && borderType === "dashed",
                flex: stories !== null,
              })}>
              <div className="item-label whitespace-nowrap">{time}</div>
              <div
                className={cx("item-tail", {
                  "border-dashed": borderType === "dashed",
                  "border-solid": borderType === "solid",
                })}></div>
              <div
                className={cx("item-head", {
                  "opacity-0": borderType === "dashed" && stories !== null,
                })}></div>
              {storyCards && <StoriesWithPopup storyCards={storyCards} />}
              <div
                className={cx("item-content -top-8", {
                  "ml-16 absolute left-3/20": borderType === "solid",
                  "ml-auto left-40 md:ml-0 absolute md:left-3/20":
                    stories === null,
                  "ml-16 md:ml-12": stories !== null,
                })}>
                {label && description ? (
                  <div className="text-gray-text font-bold font-mulish text-14-24">
                    {label}
                  </div>
                ) : (
                  label
                )}
                {description && (
                  <div
                    className={cx("text-14-20 tracking-2", {
                      "w-timeline-content": borderType === "dashed",
                    })}>
                    {description}
                  </div>
                )}
                {subdescription && (
                  <div className="text-12-22 text-gray-text-2 pt-4 tracking-2">
                    {subdescription}
                  </div>
                )}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

Timeline.defaultProps = {
  content: [],
  hasDetail: false,
};

export default Timeline;
