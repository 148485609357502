import React from "react";
import cn from "classnames";

// TODO: Remove bulletColor, unused
const HtmlList = ({ className, children, bulletColor }) => (
  <ul className={cn("list-inside list-none", className)}>
    {React.Children.map(children, (child, idx) => {
      if (React.isValidElement(child)) {
        return (
          <div key={idx} className="flex flex-nowrap text-14-24">
            <div
              className={cn(
                "shrink-0 rounded-full h-5 w-5 mt-9 mr-10",
                { "bg-gold": !bulletColor },
                bulletColor
              )}
            />
            {React.cloneElement(child, {
              className: `flex-1 ${child.props.className}`,
            })}
          </div>
        );
      }
    })}
  </ul>
);

export default HtmlList;
