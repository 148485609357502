import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { CalendarView } from "components/DatePicker/CalendarView";
import Legend from "components/Design/Legend";

export const Schedules = ({ dates, title, boatLegend }) => {
  const { t } = useTranslation(["homepage", "activityDetail"]);
  return (
    <div>
      {title && (
        <>
          <h6 className="font-mulish my-6 font-bold uppercase text-12-18 text-gold tracking-8">
            {title}
          </h6>
          <div class="border-t border-gray-light"></div>
        </>
      )}
      <CalendarView highlightedDates={dates} />
      {boatLegend && (
        <div className="w-280 mt-20 grid grid-cols-2 gap-x-14 gap-y-24 m-auto lg:w-full lg:flex lg:items-center lg:justify-between ">
          <div>
            <Legend type="green" title={t("3 Days 2 Nights")} />
          </div>
          <div>
            <Legend type="blue" title={t("2 Days 1 Night")} />
          </div>
          <div>
            <Legend type="white" title={t("Available for Charter")} />
          </div>
          <div>
            <Legend type="disabled" title={t("Not Available")} />
          </div>
        </div>
      )}
    </div>
  );
};
