import React, { useState } from "react";
import { ISO_FORMAT } from "store/constants/date";
import dayjs from "utils/dayjs";
import { MonthView } from "./MonthView";
import type { Dayjs } from "dayjs";

type CalendarViewProps = {
  highlightedDates: {
    [k: string]: "BLUE" | "GREEN";
  };
};

export const CalendarView = ({ highlightedDates }: CalendarViewProps) => {
  const [month, setMonth] = useState(dayjs().startOf("month"));

  // dates[day] === NOT_AVAILABLE || !dates[day]
  const datePropsFn = (date: Dayjs) => {
    if (highlightedDates?.[date.format(ISO_FORMAT)]) {
      return {
        color: highlightedDates[date.format(ISO_FORMAT)],
      };
    }

    return { disabled: true };
  };

  const onPrev = () => setMonth(month.subtract(1, "month"));
  const onNext = () => setMonth(month.add(1, "month"));

  return (
    <div className="relative flex justify-center lg:justify-between bg-white py-24">
      <div className="absolute top-24 left-0" onClick={onPrev}>
        <span className="ay-icon-left-arrow-thin text-gold text-24 cursor-pointer" />
      </div>
      <div className="absolute top-24 right-0" onClick={onNext}>
        <span className="ay-icon-right-arrow-thin text-gold text-24 cursor-pointer" />
      </div>
      <MonthView
        month={month}
        datePropsFn={datePropsFn}
        onHoverDate={() => undefined}
        onClickDate={() => undefined}
        plainAndScrollable={false}
      />
      <div className="hidden lg:block">
        <MonthView
          month={month.add(1, "month")}
          datePropsFn={datePropsFn}
          onHoverDate={() => undefined}
          onClickDate={() => undefined}
          plainAndScrollable={false}
        />
      </div>
    </div>
  );
};
