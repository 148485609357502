import React, { useState, useEffect } from "react";
import cx from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import {
  ArtDirectedImage,
  DivToMakeAbsoluteImageLookRelative,
} from "components/Design/ArtDirectedImage";
import Icon from "./CommonIcon/Icon";

const PrevArrow = ({ onClick, title }) => {
  return (
    <div
      className="hidden lg:block absolute inset-y-0 my-auto z-1 cursor-pointer"
      onClick={onClick}
      title={title}>
      <span className="relative h-full flex items-center">
        <Icon name="left-arrow" color="text-white" fontSize="text-34" />
      </span>
    </div>
  );
};

const NextArrow = ({ onClick, title }) => {
  return (
    <div
      className="hidden lg:block absolute inset-y-0 right-0 my-auto z-1 cursor-pointer"
      onClick={onClick}
      title={title}>
      <span className="relative h-full flex items-center">
        <Icon name="right-arrow" color="text-white" fontSize="text-34" />
      </span>
    </div>
  );
};

const Dots = ({ count, highlight, onIndicatorClick }) => {
  return (
    <ul className="space-x-12 absolute inset-x-0 bottom-20 z-1 flex justify-center lg:justify-start lg:pl-24">
      {Array.from(Array(count)).map((_, index) => {
        return (
          <li
            className={cx(
              "inline-block rounded-full h-8 w-8 cursor-pointer opacity-50 bg-white",
              {
                "opacity-100": highlight === index,
              }
            )}
            onClick={() => onIndicatorClick(index)}></li>
        );
      })}
    </ul>
  );
};

const variants = {
  enter: {
    opacity: 0,
    zIndex: 1,
  },
  center: {
    opacity: 1,
    zIndex: 1,
  },
  exit: {
    opacity: 0,
    zIndex: 0,
  },
};

const CarouselImplementation = ({
  images,
  icon,
  title,
  subHeading,
  homepageHeading,
  setShowBanner,
}) => {
  const [showContent, setShowContent] = useState(false);

  const content = Boolean(icon) || Boolean(title) || Boolean(subHeading);

  // Carousel specific
  const [index, setIndex] = useState(0);

  const goPrevious = () => {
    const newIndex = index === 0 ? images.length - 1 : index - 1;
    setIndex(newIndex);
  };

  const goNext = () => {
    setIndex((index + 1) % images.length);
  };

  const onIndicatorClick = (slideIndex) => {
    setIndex(slideIndex);
  };

  const panAnimate = (_, { offset }) => {
    if (offset.x > 0) {
      goPrevious();
    } else {
      goNext();
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      goNext();
    }, 4500);

    return () => clearTimeout(timeout);
  }, [index]);

  useEffect(() => {
    setShowBanner && setShowBanner(true);
    setShowContent(true);
  }, []);

  return (
    <div className="relative overflow-hidden">
      <AnimatePresence initial={false}>
        <motion.div
          className="absolute w-full"
          key={index}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            opacity: { duration: 1.5 },
            type: "tween",
            ease: "easeIn",
          }}
          onPan={panAnimate}>
          <ArtDirectedImage image={images[index]} loading="eager" />
        </motion.div>
      </AnimatePresence>
      <PrevArrow onClick={goPrevious} />
      <NextArrow onClick={goNext} />
      <Dots
        count={images.length}
        highlight={index}
        onIndicatorClick={onIndicatorClick}
      />
      <DivToMakeAbsoluteImageLookRelative image={images[index]} />

      {content && (
        <>
          <div className="bg-gradient-header absolute w-full top-0 pointer-events-none z-1"></div>
          <div className="absolute z-1 top-1/2 transform transition-none left-1/2 -translate-x-1/2 w-full lg:w-6/12 pointer-events-none antialiased px-24 lg:px-auto">
            <div
              className={cx(
                "transition-all transform duration-2000 ease-in-out text-white text-center w-",
                {
                  "opacity-100 -translate-y-1/2": showContent,
                  "opacity-0 translate-y-0": !showContent,
                }
              )}>
              <div>
                {icon}
                <h1 className="font-semibold text-36 leading-50 lg:text-52 lg:leading-72">
                  {subHeading}
                </h1>
                <p className="font-mulish font-bold text-16 leading-26 tracking-16 uppercase">
                  {title}
                </p>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export const BannerCarousel = ({
  images,
  bannerCarouselIcon,
  title,
  subHeading,
  homepageHeading,
  setShowBanner,
}) => {
  if (images.length === 1) {
    return <ArtDirectedImage image={images[0]} loading="eager" />;
  } else {
    return (
      <CarouselImplementation
        images={images}
        icon={bannerCarouselIcon}
        title={title}
        subHeading={subHeading}
        homepageHeading={homepageHeading}
        setShowBanner={setShowBanner}
      />
    );
  }
};
