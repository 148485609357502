import React, { useState } from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import Grid from "components/Design/Grid/Grid";
import { CardWithContent, CardActivitiesPricing } from "components/Design/Card";
import { Schedules } from "components/Design/Schedules/Schedules";
import { StoryCircle } from "components/Design/Stories";
import { CarouselStories } from "components/Design/Carousel/CarouselStories";
import dayjs from "dayjs";
import { ISO_FORMAT } from "store/constants/date";
import Legend from "components/Design/Legend";
import {
  GREEN,
  BLUE,
  MIXED,
  TWO_DAYS_ONE_NIGHT,
  THREE_DAYS_TWO_NIGHTS,
  AVAILABLE,
  NOT_AVAILABLE,
} from "store/constants/date";

const highlightedDateRange = (dateRanges) => {
  return dateRanges.reduce((acc, range) => {
    let startDay = dayjs(range.start_date);
    let endDay = dayjs(range.end_date);

    const isAvailableOrNotAvailbleDateRange =
      range.type === NOT_AVAILABLE || range.type === AVAILABLE;

    while (startDay.isBefore(endDay.add(1, "days"))) {
      const dayDate = startDay.format(ISO_FORMAT);
      if (isAvailableOrNotAvailbleDateRange) {
        acc[startDay.format(ISO_FORMAT)] = range.type;
      }
      if (range.type === TWO_DAYS_ONE_NIGHT) {
        acc[dayDate] = acc[dayDate] === GREEN ? MIXED : BLUE;
      }
      if (range.type === THREE_DAYS_TWO_NIGHTS) {
        acc[dayDate] = acc[dayDate] === BLUE ? MIXED : GREEN;
      }
      startDay = startDay.add(1, "days");
    }

    return acc;
  }, {});
};

const ActivityDetail = ({
  activityData,
  instaStory,
  schedules,
  itineraryPackage,
  activityPrice,
  scheduleTitle,
  contact,
  otherInfoList,
}) => {
  const { t } = useTranslation(["homepage", "lakodiaPackages"]);

  const instaSections = instaStory?.map((story) => {
    return {
      title: story.title,
      image: {
        mobile: story.mobile,
        desktop: story.desktop,
      },
      images: story.images.map((img) => {
        return {
          name: img.name,
          title: img.title,
          subtitle: img.subtitle,
          image: {
            mobile: img.mobile,
            desktop: img.desktop,
          },
        };
      }),
    };
  });
  const [show, setShow] = useState(false);
  const [storyImages, setStoryImages] = useState([]);

  const handleShowStories = (images) => {
    setStoryImages(images);
    setShow(true);
  };

  const handleCloseStories = () => {
    setStoryImages([]);
    setShow(false);
  };

  const highlightedDates = schedules && highlightedDateRange(schedules);

  return (
    <Grid>
      <div className="col-span-12 lg:col-span-7 relative mt-24 lg:mt-60">
        <h1 className="font-semibold text-h4 lg:text-h3 text-gray-text pb-16 lg:pb-40">
          {activityData.title}
        </h1>
        {activityPrice && (
          <div className="block lg:hidden pb-30">
            <CardActivitiesPricing
              title={activityPrice.title}
              priceList={activityPrice.priceList}
            />
          </div>
        )}
        <CardWithContent title={t("Description")}>
          <h2 className="py-16 lg:py-20 font-mulish text-14-24 text-gray-text">
            {activityData.description}
          </h2>

          {instaSections && (
            <div className="inline-flex">
              {instaSections.map(({ title, image, images }) => {
                return (
                  <StoryCircle
                    key={title}
                    title={title}
                    image={image}
                    onClick={() => handleShowStories(images)}
                  />
                );
              })}
            </div>
          )}

          <CarouselStories
            isOpen={show}
            shouldCloseOnOverlayClick
            className="popup-gallery-modal"
            overlayClassName="popup-gallery-overlay"
            onRequestClose={handleCloseStories}
            storyImages={storyImages}
          />
        </CardWithContent>
        {itineraryPackage}
        {scheduleTitle && (
          <CardWithContent title={scheduleTitle}>
            <Schedules dates={highlightedDates} />

            <div className="w-280 mt-20 grid grid-cols-[1.3fr_1fr] gap-x-14 gap-y-24 m-auto lg:w-full lg:flex lg:items-center lg:justify-between ">
              <div>
                <Legend type="green" title={t("3 Days 2 Nights")} />
              </div>
              <div>
                <Legend type="blue" title={t("2 Days 1 Night")} />
              </div>
              <div>
                <Legend type="white" title={t("Available for Charter")} />
              </div>
              <div>
                <Legend type="disabled" title={t("Not Available")} />
              </div>
            </div>
          </CardWithContent>
        )}
        {otherInfoList}
      </div>
      <div className="hidden lg:block lg:col-span-5 ml-20 relative lg:mt-60">
        <div className="py-20">
          {activityPrice && (
            <div className="mb-20 shadow-booking-form border border-gray-light">
              <CardActivitiesPricing
                className="p-20"
                title={activityPrice.title}
                priceList={activityPrice.priceList}
              />
            </div>
          )}
          <div className="mb-20">{contact}</div>
        </div>
      </div>
    </Grid>
  );
};

export default ActivityDetail;
