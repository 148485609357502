import React from "react";
import cn from "classnames";
import "./style.less";

const Legend = ({ type, title }) => (
  <div className="flex justify-start items-center">
    <div
      className={cn("w-20 h-20 border", {
        "border-gold bg-gold-accent": type === "gold",
        "border-gray-light bg-white": type === "white",
        "legend-disabled": type === "disabled",
        "border-gray-light bg-blue-light": type === "blue",
        "border-gray-light bg-green-light": type === "green",
      })}></div>
    <span className="flex items-center text-12-20 pl-8 font-mulish text-black">
      {title}
    </span>
  </div>
);

Legend.defaultProps = {
  type: "gold",
};

export default Legend;
