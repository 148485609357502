import React, { useState } from "react";
import {
  AccordionList,
  AccordionCard,
  AccordionPackage,
} from "components/Design/Accordion";
import ExpandCollapse from "components/Design/ExpandCollapse/ExpandCollapse";
import { useTranslation } from "react-i18next";
import Timeline from "../Timeline/Timeline";

const Itineraries = ({ packages }) => {
  const { t } = useTranslation();
  return (
    <AccordionList className="h-500">
      {packages.map((pack, idx) => (
        <AccordionCard
          minimumHeight={3000} //set maximum max-height
          key={idx}
          title={pack.title}
          info={t("Click to show the itinerary")}
          infoCollapse={t("Click to close")}>
          <ItineraryPackages packages={pack.days} />
        </AccordionCard>
      ))}
    </AccordionList>
  );
};

const ItineraryPackages = ({ packages }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState([{ id: 0, value: true }]);
  const handleOpen = (id) => {
    let updateOpened = [...open];
    updateOpened[id] = {
      id,
      value: !open[id]?.value,
    };
    setOpen(updateOpened);
  };
  return (
    <>
      {packages.map((item, idx) => (
        <AccordionPackage
          title={item.title}
          key={idx}
          id={idx}
          open={open[idx]?.value}
          handleOpen={handleOpen}
          bordered={!(packages.length - 1 === idx)}
          hideLabel={t("hide")}
          showLabel={t("show")}>
          <ExpandCollapse collapsed={open[idx]?.value}>
            <Timeline
              borderType="dashed"
              hasDetail
              itineraryWidth
              content={item.entries.map((entry) => {
                return {
                  label: entry.title,
                  description: entry.description,
                  stories: entry?.stories,
                };
              })}
            />
          </ExpandCollapse>
        </AccordionPackage>
      ))}
    </>
  );
};

export { Itineraries, ItineraryPackages };
