import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "components/Layout/Layout";
import { BannerCarousel } from "components/BannerCarousel";
import ActivityDetail from "components/ActivityDetail/ActivityDetail";
import PageContext from "contexts/PageContext";
import { CRUISE_INQUIRY } from "store/constants/support";
import { Itineraries } from "components/Design/Itinerary";
import { CardWithContent, CardContactUsPrimary } from "components/Design/Card";
import HtmlList from "components/Design/HtmlList";
import { textToList } from "utils/arrayFunctions";
import { generateSupportURL } from "utils/generateSupportURL";
import { transformObject } from "utils/transformv4";

const CruisePage = ({ data }) => {
  const { t } = useTranslation(["homepage", "lakodiaPackages"]);
  const {
    cms,
    allNavigationMenuJson: {
      nodes: [navigations],
    },
    allSocialMediaJson: {
      nodes: [socialMediaList],
    },
  } = data;

  const {
    sections: [destinations],
    hotelsByLocale: [{ cruises, ...hotel }],
    pagesByLocale: [
      {
        banners: [banner],
        seo_tags,
        sections,
      },
    ],
    supportDrawer: [supportDrawer],
  } = transformObject(cms);

  const cruiseDetails = cruises.find(
    (cruise) => cruise.name === "LakoDia_Cruises"
  );
  const instaStory = sections.find(
    (section) => section.name === `LKD_Packages_Instastory`
  );
  const inclusions = textToList(cruiseDetails.inclusions);
  const optionalServices = textToList(cruiseDetails.optional_services);
  const additionalInformation = textToList(
    cruiseDetails.additional_information
  );

  const supportDrawerData = {
    ...supportDrawer,
    hotel: hotel,
  };

  const dataContact = {
    url: generateSupportURL("labuan-bajo/lakodia", CRUISE_INQUIRY),
    label: t("Contact Us"),
  };

  const destination = hotel.location;

  return (
    <PageContext.Provider value={{ showBannerPadding: true }}>
      <Layout
        destination={t(destination)}
        seoTitle={t("Ayana Lako Di'a")}
        seoTags={seo_tags}
        banner={<BannerCarousel images={banner.images} />}
        showBanner={true}
        contactOnMobile={{
          url: generateSupportURL("labuan-bajo/lakodia", CRUISE_INQUIRY),
          label: t("Contact Us"),
        }}
        destinationCards={destinations.images}
        navigations={navigations}
        socialMedia={socialMediaList}
        socialMediaLabel={t("Connect with AYANA Lako di'a")}
        hotel={hotel}
        navElement="Room"
        supportDrawer={supportDrawerData}
        contactOnDesktop={dataContact}>
        <ActivityDetail
          ctaLink="/labuan-bajo/lakodia/ayana-hygiene-board/"
          activityData={cruiseDetails}
          schedules={cruiseDetails.cruise_schedule}
          scheduleTitle={t("Sail Schedules")}
          itineraryPackage={
            <CardWithContent title={t("lakodiaPackages:Sample Itinerary")}>
              <Itineraries packages={cruiseDetails.cruise_packages} />
            </CardWithContent>
          }
          contact={
            <div className="hidden md:block">
              <CardContactUsPrimary
                description={t("contactUsText")}
                contact={{
                  url: generateSupportURL(
                    "labuan-bajo/lakodia",
                    CRUISE_INQUIRY
                  ),
                  label: t("Contact Us"),
                }}
              />
            </div>
          }
          otherInfoList={
            <>
              <CardWithContent title={t("Inclusions")}>
                <HtmlList className="pt-20 font-mulish text-gray-text">
                  {inclusions.map((inclusion) => (
                    <div key={inclusion} className="leading-26">
                      {inclusion}
                    </div>
                  ))}
                </HtmlList>
              </CardWithContent>
              <CardWithContent title={t("Optional Services (Additional Fee)")}>
                <HtmlList className="pt-20 font-mulish text-gray-text">
                  {optionalServices.map((service) => (
                    <div key={service} className="leading-26">
                      {service}
                    </div>
                  ))}
                </HtmlList>
              </CardWithContent>
              <CardWithContent title={t("Additional Information")}>
                <HtmlList className="pt-20 font-mulish text-gray-text">
                  {additionalInformation.map((information) => (
                    <div key={information} className="leading-26">
                      {information}
                    </div>
                  ))}
                </HtmlList>
              </CardWithContent>
            </>
          }
          instaStory={instaStory?.cards}
        />
        <div className="pb-10 md:pb-20"></div>
      </Layout>
    </PageContext.Provider>
  );
};

export const query = graphql`
  query CruiseTemplate($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allNavigationMenuJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      ...LocalNavigation
    }
    allSocialMediaJson(filter: { hotel: { eq: "AYANA Lako Di'a" } }) {
      nodes {
        hotel
        socmed {
          link {
            en
            ja
          }
          iconName
        }
      }
    }
    cms {
      hotelsByLocale(language: $language, where: { name: "AYANA Lako Di'a" }) {
        data {
          id
          attributes {
            hotel_code
            name
            phone
            location
            email
            whatsapp_contact
            custom_whatsapp_message
            cruises {
              data {
                id
                attributes {
                  name
                  title
                  description
                  inclusions
                  optional_services
                  additional_information
                  cruise_packages {
                    data {
                      id
                      attributes {
                        name
                        title
                        days {
                          day
                          title
                          entries {
                            title
                            notes
                            description
                            stories {
                              ...CmsStorieEntityResponse_v4
                            }
                          }
                        }
                      }
                    }
                  }
                  cruise_schedule {
                    name
                    start_date
                    end_date
                    type
                  }
                }
              }
            }
            offices(sort: "sequence") {
              ...CmsOffices_v4
            }
            ...CmsHotelLogo_v4
          }
        }
      }
      supportDrawer: sections(filters: { name: { eq: "LKD_Support_Drawer" } }) {
        data {
          id
          attributes {
            ...CmsSection_v4

            images {
              ...CmsImageFullWidth_v4
            }
          }
        }
      }
      pagesByLocale(language: $language, where: { name: "LKD_Packages" }) {
        data {
          id
          attributes {
            ...CmsPageSeoTags_v4
            banners {
              ...CmsBannerFullWidth_v4
            }
            sections {
              data {
                id
                attributes {
                  name
                  cards {
                    ...CmsCardFullWidth_v4
                  }
                }
              }
            }
          }
        }
      }
      sections(filters: { name: { eq: "LKD_Footer_Destination" } }) {
        data {
          id
          attributes {
            title

            images(sort: "sequence") {
              ...CmsImageFullWidth_v4
            }
          }
        }
      }
    }
  }
`;
export default CruisePage;
