import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";
import Icon from "components/CommonIcon/Icon";

export const AccordionCard = ({
  title,
  info,
  infoCollapse,
  children,
  id,
  open,
  setOpen,
  lightBottomBordered,
  minimumHeight,
}) => {
  const [height, setHeight] = useState(0);

  const accordionContent = useRef(null);

  useEffect(() => {
    setHeight(
      !open ? 0 : minimumHeight + accordionContent.current.scrollHeight
    );
  }, [open, minimumHeight]);

  const contentVisible = height > 0;
  return (
    <div
      className={cx(
        "flex flex-col overflow-hidden font-mulish my-10",
        { "border-b border-gray-light": lightBottomBordered },
        { "border rounded-sm border-gray-light": !contentVisible },
        { "shadow-shadow-focus": contentVisible }
      )}>
      <div
        className="flex justify-between py-12 px-12 cursor-pointer"
        onClick={() => setOpen(id, open)}>
        <div className="pl-0">
          <p className="text-gray-text text-14-24 normal-case tracking-normal">
            {title}
          </p>
          {info && infoCollapse && (
            <p className="text-gray-dark text-10-12 font-semibold tracking-2">
              {contentVisible ? infoCollapse : info}
            </p>
          )}
        </div>

        {contentVisible ? (
          <span className="my-auto w-16 h-16 ml-2 mr-8 transform -rotate-90">
            <Icon name="right-arrow" fontSize="text-16" />
          </span>
        ) : (
          <span className="my-auto  mr-8  w-16 h-16">
            <Icon name="right-arrow" fontSize="text-16" />
          </span>
        )}
      </div>

      <div
        ref={accordionContent}
        style={{ maxHeight: `${height}px` }}
        className={cx(
          "text-gray-text overflow-hidden px-12 transition-all duration-300 ease",
          {
            "mb-0": contentVisible,
          }
        )}>
        {children}
      </div>
    </div>
  );
};

export const AccordionPackage = ({
  title,
  children,
  id,
  handleOpen,
  open,
  setOpen,
  hideLabel,
  showLabel,
  bordered,
}) => {
  return (
    <div
      className={cx("flex flex-col overflow-hidden font-mulish", {
        "border-gray-light border-b": bordered,
      })}>
      <div
        key={id}
        className="flex justify-between py-12 px-0 cursor-pointer"
        onClick={() => handleOpen(id, open)}>
        <div className="flex font-mulish font-bold text-white uppercase text-12-18 tracking-4 bg-gold-accent py-1 px-16 rounded-sm border-0 tracking-8">
          {title}
        </div>

        <div className="text-12-22 font-mulish font-semibold tracking-16 uppercase text-gray-text-2 my-auto">
          {open ? hideLabel : showLabel}
        </div>
      </div>

      <div
        className={cx(
          "text-gray-text overflow-hidden transition-all duration-300 ease",
          {
            "mb-12": open,
          }
        )}>
        {children}
      </div>
    </div>
  );
};

export const Accordion = ({
  title,
  titleWhenExpanded = title,
  children,
  id,
  open,
  setOpen,
  withArrow,
  collapseFromBottom,
}) => {
  const [height, setHeight] = useState(0);

  const accordionContent = useRef(null);

  useEffect(() => {
    setHeight(!open ? 0 : accordionContent.current.scrollHeight);
  }, [open]);

  const contentVisible = height > 0;
  return (
    <div className="flex flex-col overflow-hidden font-mulish border-b border-gold border-opacity-50">
      {collapseFromBottom && (
        <div
          ref={accordionContent}
          style={{ maxHeight: `${height}px` }}
          className={cx(
            "text-gray-text overflow-hidden transition-all duration-300 ease",
            {
              "mb-12": contentVisible,
            }
          )}>
          {children}
        </div>
      )}

      {withArrow ? (
        <div
          className="flex items-center py-12 pr-12 cursor-pointer"
          onClick={() => setOpen(id, open)}>
          {Boolean(height) ? (
            <span className=" ml-2 mr-8 transform rotate-90">
              <Icon name="right-arrow" fontSize="text-16" />
            </span>
          ) : (
            <span className="mr-8">
              <Icon name="right-arrow" fontSize="text-16" />
            </span>
          )}
          <p className="text-gold font-semibold text-12 tracking-16 uppercase">
            {contentVisible ? titleWhenExpanded : title}
          </p>
        </div>
      ) : (
        <div
          className="flex justify-between py-12 pr-12 cursor-pointer"
          onClick={() => setOpen(id, open)}>
          <p className="text-gold font-semibold text-12 tracking-16 uppercase">
            {contentVisible ? titleWhenExpanded : title}
          </p>
          {contentVisible ? (
            <span className="my-auto shrink-0">
              <Icon name="minus" fontSize="text-16" />
            </span>
          ) : (
            <span className="my-auto shrink-0">
              <Icon name="plus" fontSize="text-16" />
            </span>
          )}
        </div>
      )}
      {!collapseFromBottom && (
        <div
          ref={accordionContent}
          style={{ maxHeight: `${height}px` }}
          className={cx(
            "text-gray-text overflow-hidden transition-all duration-300 ease",
            {
              "mb-12": contentVisible,
            }
          )}>
          {children}
        </div>
      )}
    </div>
  );
};

export const AccordionList = ({ children }) => {
  const [openAccordion, setOpenAccordion] = useState();
  const AccordionChild = React.Children.map(children, (child, idx) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        id: idx,
        open:
          openAccordion?.id === idx
            ? openAccordion?.value
            : false || child.props.openOnload,
        setOpen: (id, value) =>
          setOpenAccordion({ id: id, value: id === idx && !value }),
      });
    }
  });
  AccordionList.defaultProps = {
    withArrow: false,
    openOnload: false,
    minimumHeight: 0,
    bordered: true,
  };
  return AccordionChild;
};
